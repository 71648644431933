.map-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    width: 100%;
    height: 100%;
}

.map {
    width: 100%;
    min-height: 420px;
    align-self: center;
    -webkit-align-self: center;
}

.contact-us .flex-right .fullpage-subtitle {
    position: relative;
    font-family: 'Avenir Next LT Pro Regular', sans-serif;
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
    color: #3C3D41;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
}

@media (orientation: portrait) and (max-width: 480px) {

    .contact-us .flex-right .fullpage-subtitle {
        font-size: 20px;
        line-height: 20px;
    }
}