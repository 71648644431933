.contact-us {
    background: #f2f2f2;
}

.confirm {
    display: none;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    z-index: 1000;
}

.confirm-box {
    width: 350px;
    height: 200px;
    background: #fff;

    position: absolute;
    top: 50%;
    left: 50%;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.form-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

form {
    position: relative;
    -webkit-align-self: flex-start;
    align-self: flex-start;
}

input {
    font-family: sans-serif;
}

input[type="submit"] {
    position: relative;
    float: left;
    outline: none;
}

input:disabled {
    background: #ccc;
    cursor: auto;
}

textarea {
    font-family: sans-serif;
    padding-top: 1.5%;
}

textarea {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textarea;
    background-color: white;
    -webkit-rtl-ordering: logical;
    flex-direction: column;
    resize: auto;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin: 0em;
    font: 400 11px system-ui;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    padding: 2px;
}

.name-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 95%;
}

.input {
    position: relative;
    width: 95%;
    height: 4vh;
    float: left;
    box-sizing: border-box;
    border: solid 0px #bbb;
    outline: none;
    font-size: 16px;
    color: rgb(0,0,0);
    background-color: rgb(255, 255, 255);
    padding-left: 1.5%;
    margin-bottom: 3vh;
    -webkit-transition: background 0.1s;
    transition: background 0.1s;
}

#lname {
    margin-left: 10px;
}

.button-send {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 30%;
    max-width: 330px;
    padding: 3.5% 3.5%;
    border: none;
    text-decoration: none;
    color: #fff;
    z-index: 50;
    background-color: #008FD5;
    cursor: pointer;
    font-size: 0.8em;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
}

textarea {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textarea;
    background-color: white;
    -webkit-rtl-ordering: logical;
    flex-direction: column;
    resize: none;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin: 0;
    font: 400 11px system-ui;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    padding: 2px;
    height: 200px !important;
}