
.nav {
    display: -webkit-flex;
    display: flex;
    position: fixed;
    top:0;
    z-index: 300;
    width: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    border-top: solid 0.5vh #008FD5;
    border-bottom: solid .01vh #c4c4c4;
    background-color: rgb(255,255,255);
    -webkit-transition: -webkit-transform 0.5s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.logo {
    background-color: transparent;
    object-fit: contain;
    height: 70px;
    width: 10%;
    margin-left: 5.2%;
    align-self: center;
    padding-top: 0;
    -webkit-align-self: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 5;
    border: none;
}

.nav-flex {
    width: 100%;
    padding-right: 5%;
    padding-top: 0.5vh;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.nav-item {
    padding-bottom: 10px;
    color: #383838;
    border-bottom: solid 1px rgba(0,0,0,0);
    z-index: 301;
    font-size: 2vh;
    font-family: 'Avenir Next LT Pro Regular', sans-serif;
    margin-right: 20px;
    margin-left: 20px;
    /* margin-top: 3%; */
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
    transition: color 0.3s, border 0.3s;
}

@media (orientation: portrait) {
    .nav {
        position: absolute;
        background-color: transparent;
        border-bottom: none;
    }

    .logo {
        position: absolute;
        top: 3px;
        left: 10px;
        width: unset;
        height: 30px;
        margin: 0;
    }

    .nav-flex {
        display: none;
    }
}