.video-player {
    position: relative;
}

.play-button {
    position: absolute;
    width: 80px;
    height: 80px;

    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}