.license-logos {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}

.license-logos .license-logo-container {
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;         /* IE 11 */
    display:table;                      /* Actually FF 20+ */

    width: 100%;
    height: 200px;
    text-align: center;
}

.license-logos .license-logo-container img {
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    object-fit: contain;
}

@media (orientation: portrait) {

    .license-logos .license-logo-container {

        width: 100%;
        height: unset;
        text-align: center;
    }

    .license-logos .license-logo-container img {
        position: relative;
        width: 100%;
        height: unset;
        margin-bottom: 10px;
    }

}