
.employee-profile {
    display: flex;
    flex-direction: column;
    margin: 0 0 40px;
    cursor: pointer;
}

.employee-profile .profile-photo-container {
    position: relative;
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
}

.employee-profile .profile-photo-container .employee-summary {
    position: absolute;
    display: none;
    background: rgba(0,0,0,0.5);
    width:100%;
    max-height: 60%;
    overflow-y: hidden;
    left: 0;
    bottom: 0;
    font-size: 13px;
    color: #fff;
    padding: 10px 10px 5px;
}

.employee-profile .profile-photo-container .employee-image {
    width:100%;
    height:300px;
    object-fit: cover;
}

.employee-profile .name {
    font-size: 30px;
}

.employee-profile .title {
    font-size: 20px;
    font-weight: 500;
}

