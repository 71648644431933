:root {
    --panel-width: 450px;
    --panel-height: 100%;
    --panel-close-height:100%;
}

.visualizer {
    width: var(--app-width);
    height: var(--app-height);
    overflow: hidden;
    position: relative;
}

.visualizer-dat-gui {
    top:auto;
    bottom: 20px;
    z-index: 1000;
}

.cbarview {
    width: 100%;
    height: 100%;
}

.capture-button {
    position: fixed;
    z-index: 100;
    bottom: 40px;
    left:50%;
}

#drawing-mark-overlay {
    opacity: 0.6;
    background-color: #00afff;
}

.product-selector {
    position: absolute;
    top:0;
    left:calc(-1 * var(--panel-width));
    width:var(--panel-width);
    height: var(--panel-height);

    font-family: Avenir, sans-serif;
    display: flex;
    flex-direction: row;
    transition: left 200ms;
}

.product-selector.open {
    left:0;
}

.product-selector .panel {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    background: #ffffff;
    min-width:var(--panel-width);
}

.product-selector .panel .title {
    background: #1f1f1f;
    color: #ffffff;
    font-weight: 800;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    user-select: none;
}

.product-selector .panel .title > div {
    cursor: pointer;
}

.product-selector .close-button-container {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    height: var(--panel-close-height);
}

.product-selector .close-button {
    cursor: pointer;
    color: white;
    pointer-events: auto;
}

.product-selector .close-button i {
    font-size: 40px;
}

.horizontal-swatch-listing-content {

}

.horizontal-swatch-listing-image {
    height: 95px;
    border: 1px solid #aeaeae;
}

.tiled-grid-selector {
    right:100px;
    background: white;
    font-family: Avenir, sans-serif;
}

/*.tiled-grid-selector-element.selected {*/
/*    border: solid 2px #1f1f1f;*/
/*}*/

.tiled-grid-selector-element:hover {

}

.tiled-grid-selector-element .selected {

}

.tiled-grid-selector-element-image {
    filter: brightness(0%) grayscale(100%);
}

.tiled-grid-selector-element:hover .tiled-grid-selector-element-image {
    filter: brightness(100%) grayscale(100%);
}

/* Mobile Phone or iPad in portrait */
@media (orientation: portrait) {

    :root {
        --panel-width: 100vw;
        --panel-height: 70%;
        --panel-close-height: 96px;
    }

    .product-selector {
        top:unset;
        bottom: calc(-1 * var(--panel-height) + var(--panel-close-height));
        left:0;
        transition: bottom 200ms;
        flex-direction: column;
        align-items: center;
    }

    .product-selector.open {
        bottom:0;
    }

    .product-selector .panel {
        order: 1;
        height: var(--panel-height);
        min-width:var(--panel-width);
    }

    .product-selector .close-button-container {
        order: 0;
    }
}

/* Mobile Only */
@media only screen and (max-device-width: 480px) {

}

/* iPad Portrait Only */
@media only screen and (min-device-width: 768px) and (orientation: portrait)  {

}

/* Small screen or iPad Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape)  {

}