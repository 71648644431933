.footer {
    padding: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.follow-flex {
    display: flex;
    width: 1200px;
    max-width: 90vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.copyright {
    font-family: 'Avenir Next LT Pro Regular', sans-serif;
}

.copyright-text {
    font-size: 2vh;
    margin: 3vh 3vh 3vh 0;
    font-weight: 500;
    text-align: left;
    align-self: center;
}

.follow-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
}

.follow-item {
    margin: 2vh;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
}

.follow-item.first {
    margin-left: 0;
    width: 43px;
    height: 43px;
}

.follow-item.last {
    margin-right: 0;
}

@media (orientation: portrait) and (max-width: 480px) {
    .follow-menu {
        width: 30%;
        min-width: 120px;
    }

    .follow-item {
        margin: 1vh;
        width: 30px;
        height: 30px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .follow-item.first {
        margin-left: 0;
        width: 32px;
        height: 32px;
    }
}