
.site-section {
    width: 100%;
    min-height: 50vh;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Avenir Next LT Pro Regular', sans-serif;
}

.site-section-container {
    max-width: 1200px;
    width: 100%;
}

.site-section img {
    max-height: 100vh;
}

.row-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
}

.row-flex video, .row-flex img {
    max-width: 100%;
    max-height: 100%;
    padding-right: 10px;
}

.flex-left {
    flex-basis: 50%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-right: 20px;
    height: 100%;
}

.flex-right {
    flex-basis: 50%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 20px;
    height: 100%;
}

.content-left .flex-left, .content-right .flex-right {
    flex-basis: 40%;
}

.content-left .flex-right, .content-right .flex-left {
    flex-basis: 60%;
}

.fullpage-title {
    position: relative;
    font-size: 50px;
    line-height: 50px;

    font-weight: 500;
    color: #3C3D41;
    text-transform: uppercase;
    margin-top: 50px;
    border-bottom: solid 0.5vh #008FD5;
}

.fullpage-subtitle {
    position: relative;
    font-size: 35px;
    line-height: 50px;

    font-weight: 500;
    color: #3C3D41;
}

.fullpage-text {
    align-self: stretch;
    position: relative;
    text-align: left;
    color: #444;

    font-size: 18px;
    line-height: 40px;

    letter-spacing: 1px;
}

@media (orientation: portrait) {
    .row-flex {
        flex-direction: column;
    }

    .flex-left, .flex-right {
        flex-basis: 100%;
        max-width: 100%;
        height: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;
    }

    .row-flex video, .row-flex img {
        max-width: 100%;
        max-height: 100%;
        padding: 0;
    }
}

@media (orientation: portrait) and (max-width: 1024px) {
    .fullpage-title {
        font-size: 3.0vmax;
        line-height: 4.0vmax;
    }

    .fullpage-subtitle {
        font-size: 2.5vmax;
        line-height: 3.5vmax;
    }

    .fullpage-text {
        font-size: 2.0vmax;
        line-height:3.0vmax;
    }

}