/* landing page */

.home-page
{
    font-family: 'Avenir Next LT Pro Regular', sans-serif;
    overflow-x: hidden;
    max-width: 100vw;
}

.home-page-video-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.home-page-video
{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.home-page-video video {
    object-fit: cover;
}

.home-page-content {
    width: 100%;
    height: 100vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home-page-content h1 {
    color: #fff;
    font-size: 8vmin;
    font-weight: 500;
    z-index: 5;
    margin-top: 0;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 4%;
    text-shadow: 1px 1px 2px #626262;
}

.home-page-content h2 {
    color: #fff;
    font-family: 'PT Serif', serif;
    font-size: 4.0vmin;
    font-weight: normal;
    z-index: 5;
    margin: 1% 10%;
    text-shadow: 1px 1px 2px #626262;
}

.app-button-grid {
    position: relative;
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    height: 15vh;
    width: 100%;
}

.app {
    padding: 30px;
}

.app img {
    position: relative;
    background-color: transparent;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.download-text {
    position: relative;
    text-align: center;
    color: #000;
    font-weight: 300;
    font-size: 1.3em;
    margin: 0;
}

.tech-video {
    width: 1024px;
    height: 600px;
    max-width: 100%;
    object-fit: cover;
}

.video-dim {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    width: 90%;
    height: 100%;
}

.image-flex-left {
    flex-basis: 70%;

    display: flex;
    flex-direction: row;
}

.image-flex-left img {
    max-width: 100%;
    padding: 20px;
}

.image-flex-right {
    flex-basis: 30%;
}

.web-flooring-image {
    width: 100%;
}

@media (orientation: portrait) {
    .home-page-video-container {
        height: 60vh;
    }

    .home-page-video
    {

    }

    .home-page-content {
        width: 100%;
        height: 60vh;
        justify-content: flex-end;
        padding-bottom: 20px;
    }

    .download-text {
        margin-top: 30px;
    }

    .video-dim {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }
}