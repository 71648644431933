.company-profile {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
}

@media (orientation: portrait) {
    .company-profile {
        -webkit-column-count: unset; /* Chrome, Safari, Opera */
        -moz-column-count: unset; /* Firefox */
        column-count: unset;
    }
}